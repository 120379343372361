import Send from '../../trans.client.js'

export default {
    // 긴급양하 신청에 필요한 데이터 조회
    findEmergencyShippingReqInfo (params) {
      return Send({
        method: 'get',
        url: '/trans/import/on-going/emergency-shipping-req',
        params: params
      })
    },
    // 긴급양하 신청 데이터 등록 (나의 요청사항)
    insertEmergencyShippingReqLog (params) {
      return Send({
        method: 'post',
        url: '/trans/import/on-going/emergency-shipping-req',
        data: params
      })
    },
    // 긴급양하 신청 이메일 발신자 체크
    checkEmergencyShippingMailUno (params) {
      return Send({
        method: 'get',
        url: '/trans/import/on-going/check-emergency-shipping-req-uno',
        params: params
      })
    }
}
