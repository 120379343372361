var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "800px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v(" close ")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONIM010P070.001"))),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("form", { attrs: { id: "mailForm" } }, [
            _c("table", { staticClass: "tbl_row" }, [
              _vm._m(0),
              _c("tbody", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P070.002")))]),
                  _c("td", [_vm._v(_vm._s(_vm.params.subject))]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P070.003")))]),
                  _c("td", [_vm._v(_vm._s(_vm.params.customer))]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P070.004")))]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.fromEmail,
                          expression: "params.fromEmail",
                        },
                      ],
                      attrs: { type: "text", id: "fromEmail" },
                      domProps: { value: _vm.params.fromEmail },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.params, "fromEmail", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P070.005")))]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.toEmail,
                          expression: "params.toEmail",
                        },
                      ],
                      attrs: { type: "text", id: "toEmail" },
                      domProps: { value: _vm.params.toEmail },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.params, "toEmail", $event.target.value)
                          },
                          function ($event) {
                            return _vm.checkValidationEmail("toEmail")
                          },
                        ],
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P070.006")))]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.ccEmail,
                          expression: "params.ccEmail",
                        },
                      ],
                      attrs: { type: "text", id: "ccEmail" },
                      domProps: { value: _vm.params.ccEmail },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.params, "ccEmail", $event.target.value)
                          },
                          function ($event) {
                            return _vm.checkValidationEmail("ccEmail")
                          },
                        ],
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "button",
            {
              staticClass: "button blue lg",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.sendEmail()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("msg.ONIM010P070.007")) + " ")]
          ),
          _c(
            "button",
            {
              staticClass: "button gray lg ml5",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("msg.ONIM010P070.008")) + " ")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "130" } }), _c("col")])
  },
]
render._withStripped = true

export { render, staticRenderFns }