<template>
  <div class="popup_wrap" style="width:800px;">
    <!--  popup_wrap  -->
    <button
      type="button"
      class="layer_close"
      @click="$emit('close')"
    >
      close
    </button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">{{ $t('msg.ONIM010P070.001') }}</h1><!-- 긴급양하 신청 -->
      <div class="content_box">
        <!-- content_box -->
        <form id="mailForm">
          <table class="tbl_row">
            <colgroup>
              <col width="130"><col>
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('msg.ONIM010P070.002') }}</th><!-- Subject -->
                <td>{{ params.subject }}</td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONIM010P070.003') }}</th><!-- Customer -->
                <td>{{ params.customer }}</td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONIM010P070.004') }}</th><!-- From -->
                <td>
                  <input
                    v-model="params.fromEmail"
                    type="text"
                    id="fromEmail"
                  />
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONIM010P070.005') }}</th><!-- TO -->
                <td>
                  <input
                    v-model="params.toEmail"
                    type="text"
                    id="toEmail"
                    @input="checkValidationEmail('toEmail')"
                  />
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONIM010P070.006') }}</th><!-- CC -->
                <td>
                  <input
                    v-model="params.ccEmail"
                    type="text"
                    id="ccEmail"
                    @input="checkValidationEmail('ccEmail')"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div><!-- content_box // -->
      <div class="mt10 text_center">
        <button
          type="button"
          class="button blue lg"
          @click="sendEmail()"
        >
          {{ $t('msg.ONIM010P070.007') }}
        </button><!-- 신청 -->
        <button
          type="button"
          class="button gray lg ml5"
          @click="$emit('close')"
        >
          {{ $t('msg.ONIM010P070.008') }}
        </button><!-- 취소 -->
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import { rootComputed } from '@/store/helpers'
import commonPdf from '@/plugins/commonPdf'
import emergencyShipping from '@/api/rest/trans/emergencyShipping'
import commonUtils from '@/plugins/commonUtils'
import arrivalNotice from '@/api/rest/trans/arrivalNotice'

export default {
  name: 'EmergencyShippingReqPop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNo: '',
          portCd: '',
          eiCatCd: 'I'
        }
      }
    }
  },
  data: function () {
    return {
      items: [],
      pdf: {},
      emailUrl: '',
      // emailFiles: [],
      params: {
        subject: '',
        customer: '',
        fromEmail: 'DOCID@EKMTC.COM',
        toEmail: '',
        ccEmail: '',
        message: ''
      },
      podPortManager: {},
      deadlineInfoDetail: {},
      importManagerDetail: {},
      importNoneManagerDetail: {}
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    // console.log('this.auth')
    // console.log(this.auth)
    // console.log('this.memberDetail')
    // console.log(this.memberDetail)
    // console.log('this.selectedProfile')
    // console.log(this.selectedProfile)
    this.init()
  },
  methods: {
    async init () {
      // 로그인 여부 체크
      if (!this.auth.userId) {
        await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.REPORT_PDF.115') }) // 로그인을 확인해주세요.
        this.$emit('close')
        return
      }

      this.params = { ...this.params, ...this.parentInfo }

      this.params.customer = this.auth.userName
      this.params.ccEmail = 'DOCID@EKMTC.COM,' + this.selectedProfile.emlAddr

      this.items = []

      const spltBlNo = this.params.blNo.trim().split(':')
      if (spltBlNo !== undefined && spltBlNo.length > 0) {
        spltBlNo.forEach(blNo => {
          this.items.push({
            portCd: this.params.portCd,
            parm1: blNo
          })
        })
      }

      // 발신자 정보 조회
      const result = await arrivalNotice.findImportManagerDeadlineInfo((this.params.blNo))
      console.log('findImportManagerDeadlineInfo @@@@@ ', result)
      this.deadlineInfoDetail = { ...result.data.deadlineInfoDetail }
      this.importManagerDetail = { ...result.data.importManager }
      this.importNoneManagerDetail = { ...result.data.importNoneManager }

      await this.getEmergencyShippingReqInfo()

      // 대산,포항,광양일때 from, cc mail 별도 할당
      if (this.params.portCd === 'DSN' || this.params.portCd === 'KPO' || this.params.portCd === 'KAN') {
        const rtnObj = commonUtils.plcFnc(this.params.portCd, '')
        if (rtnObj) {
          this.podPortManager = rtnObj
        }

        this.params.fromEmail = this.podPortManager.usrEmail
        this.params.ccEmail = this.podPortManager.usrEmail + ',' + this.selectedProfile.emlAddr
      }
    },
    async getEmergencyShippingReqInfo () {
      await emergencyShipping.findEmergencyShippingReqInfo(this.params)
        .then(response => {
          // console.log(response)
          const emailInfoList = response.data.emergencyShippingEmailInfoList
          const reqInfoList = response.data.emergencyShippingReqInfoList

          if (emailInfoList !== undefined && emailInfoList.length > 0) {
            let tmpArr = []

            emailInfoList.forEach(emailInfo => {
              if (!this.$ekmtcCommon.isEmpty(emailInfo.picEmlAddr)) {
                tmpArr.push(emailInfo.picEmlAddr)
              }
            })

            this.params.toEmail = tmpArr.join(',')
          }

          if (reqInfoList !== undefined && reqInfoList.length > 0) {
            let formBody1 = ''
            let formBody2 = ''
            let formBodyTmp = ''
            let subject = this.$t('msg.ONIM010P070.009')

            for (let i = 0; i < reqInfoList.length; i++) {
              const reqInfo = reqInfoList[i]

              if (i === 0) {
                this.params.subject = `${subject} (${reqInfo.vslNm} / ${reqInfo.voyNo} / ${reqInfo.polPortCd})`

                formBody1 = '<HTML><body>' +
                            '<TABLE border="0" cellpadding="4" cellspacing="1" width="800">' +
                            '<TR><TD>1. 평소 업무 협조에 감사드립니다.<BR><BR>' +
                            '2. 표제의 건과 관련하여, 하기의 컨테이너의 긴급양하 및 반입을 요청<BR>' +
                            '   드리오니 원활한 협조 부탁드립니다.<BR><BR><BR>' +
                            `${reqInfo.comVslVoy}<BR>` +
                            `${reqInfo.comCy}<BR></TD></TR>` +
                            '</TR></TABLE><BR>' +
                            '<p align="left"><TABLE border="1" cellpadding="0" cellspacing="0" width="800"> </p>' +
                            '<col style="width:13%">' +
                            '<col style="width:14%">' +
                            '<col style="width:15%">' +
                            '<col style="width:7%">' +
                            '<col style="width:7%">' +
                            '<tr><Td align="center" ><B>VSL/VOY</B></Td>' +
                            '<Td align="center" ><B>B/L No</B></Td>' +
                            '<Td align="center" ><B>Container</B></Td>' +
                            '<Td align="center" ><B>Size</B></Td>' +
                            '<Td align="center" ><B>Type</B></Td></TR>'
              }

              formBodyTmp = '<tr>' +
                            `<Td align="center" ><B>${reqInfo.vslNm}<BR>${reqInfo.voyNo}</B></Td>` +
                            `<Td align="center" ><B>${reqInfo.blNo}</Td>` +
                            `<Td align="center" ><B>${reqInfo.cntrNo}</B></Td>` +
                            `<Td align="center" ><B>${reqInfo.cntrSzCd}</B></Td>` +
                            `<Td align="center"  ><B>${reqInfo.cntrTypCd}</B></Td>` +
                            '</tr>'

              formBody2 += formBodyTmp
            }

            this.params.message = formBody1 + formBody2
          }

          // console.log('this.params.message')
          // console.log(this.params.message)
        })
        .catch(error => {
          console.log(error)
        })
    },
    async checkMailFromUno (params) {
      let result

      await emergencyShipping.checkEmergencyShippingMailUno(params)
      .then((response) => {
        // console.log('checkEmergencyShippingMailUno response @@@@@@ ', response)

        result = response.data.checkUnoCnt
      })

      return result
    },
    async sendEmail () {
      if (!this.checkValidationAll()) {
        return
      }

      const params = {
        blNo: this.params.blNo,
        reqPicNm: this.selectedProfile.picNm,
        uno: this.importManagerDetail.uno
      }

      // 메일 발신자 체크
      const result = await this.checkMailFromUno(params)

      // 긴급양하 신청 LOG 등록 (나의 요청사항)
      await emergencyShipping.insertEmergencyShippingReqLog(params)
        .then(async (response) => {
          if (response.headers.respcode === 'C0000') {
            let usrNo = result > 0 ? this.importManagerDetail.uno : 'TDSID' //this.auth.userId
            let ctrCd = this.memberDetail.userCtrCd

            this.pdf = await commonPdf.setUrgentGetOff(this.items, usrNo, ctrCd)
            this.emailUrl = this.pdf.emailUrl

            // console.log('this.emailUrl')
            // console.log(this.emailUrl)

            commonPdf.sendEmail(this, this.emailUrl, this.params)
          } else {
            this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM050P020.005'))
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    checkValidationAll () {
      // const arrId = ['fromEmail', 'toEmail', 'subject', 'message']
      const arrId = ['fromEmail']
      let isOk = true

      arrId.forEach(id => {
        if (!this.checkValidation(id)) {
          isOk = false
        }
      })

      if (!this.checkValidationEmail('toEmail')) {
        isOk = false
      }
      if (!this.checkValidationEmail('ccEmail')) {
        isOk = false
      }

      return isOk
    },
    checkValidation (id) {
      const frm = document.querySelector('#mailForm')
      const selector = frm.querySelector('#' + id)
      let isOk = true

      this.$ekmtcCommon.hideErrorTooltip(selector)

      switch (id) {
        case 'fromEmail':
          if (this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.REPORT_PDF.110'))
            isOk = false
          } else if (!this.$ekmtcCommon.checkEmail(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.REPORT_PDF.111'))
            isOk = false
          }
          break
        case 'toEmail':
          if (this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.REPORT_PDF.110'))
            isOk = false
          }
          break
        case 'ccEmail':
          if (this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.REPORT_PDF.110'))
            isOk = false
          }
          break
      }

      return isOk
    },
    checkValidationEmail (id) {
      const frm = document.querySelector('#mailForm')
      const selector = frm.querySelector('#' + id)
      let res
      let isOk = true
      this.$ekmtcCommon.hideErrorTooltip(selector)
      switch (id) {
        case 'toEmail':
          res = this.checkEmail(selector.value)
          if (res !== '') {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.REPORT_PDF.112', { errEmail: res }))
            isOk = false
          }
          break
        case 'ccEmail':
          res = this.checkEmail(selector.value)
          if (res !== '') {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.REPORT_PDF.112', { errEmail: res }))
            isOk = false
          }
          break
      }
      return isOk
    },
    checkEmail (str) {
      if (str === '') {
        return ''
      }

      const arrEmail = []
      const arr = str.split(',')
      let arr2

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].indexOf(';') > -1) {
          arr2 = arr[i].split(';')
          for (let j = 0; j < arr2.length; j++) {
            arrEmail.push(arr2[j])
          }
        } else {
          arrEmail.push(arr[i])
        }
      }

      if (arrEmail.length === 0) {
        return ''
      }

      const errEmail = []

      for (let i = 0; i < arrEmail.length; i++) {
        if (!this.$ekmtcCommon.checkEmail(arrEmail[i])) {
          errEmail.push(arrEmail[i])
        }
      }
      return errEmail.join(', ')
    }
  }
}
</script>
